import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";

type FlexDisplayTypes = "flex" | "inline-flex";
type FlexAlignTypes =
  | "flex-start"
  | "flex-end"
  | "center"
  | "baseline"
  | "stretch";
type FlexJustifyTypes =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around";

interface FlexProps {
  display?: FlexDisplayTypes;
  alignItems?: FlexAlignTypes;
  justifyContent?: FlexJustifyTypes;
}

interface PositionProps {
  position: "absolute" | "relative" | "fixed" | "sticky";
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      // Neutral palette
      neutral900: "#101828",
      neutral800: "#344054",
      neutral700: "#55657C",
      neutral600: "#75819A",
      neutral500: "#98A2B3",
      neutral400: "#D3D9E2",
      neutral300: "#E9EBF0",
      neutral200: "#F2F4F7",
      neutral100: "#F9FAFB",
      neutral50: "#FCFCFD",
      // Absolute
      black: "#000",
      white: "#fff",
      // Brand
      brand800: "#081238",
      brand700: "#111C4B",
      brand600: "#00187A",
      brand500: "#0031B7",
      brand400: "#0057FF",
      brand300: "#99C6FF",
      brand200: "#CDE3FF",
      brand100: "#D0E6FF",
      brand50: "#EEF7FF",
      // Danger
      danger600: "#912018",
      danger500: "#B42318",
      danger400: "#F04438",
      danger300: "#FDA29B",
      danger200: "#FECDCA",
      danger100: "#FEE4E2",
      danger50: "#FEF3F2",
      // Warning
      warning600: "#93370D",
      warning500: "#B54708",
      warning400: "#F79009",
      warning300: "#FDB022",
      warning200: "#FEC84B",
      warning100: "#FFEAB0",
      warning50: "#FFFAEA",
      // Success
      success600: "#045A54",
      success500: "#0B8766",
      success400: "#17BC81",
      success300: "#7AE2A4",
      success200: "#ABF3C8",
      success100: "#D5FCE5",
      success50: "#E8FFF1",
      // Others
      input: "#7f7f7f",
    },
    fonts: {
      sans: "DM Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
      mono: "SF Mono, Andale Mono, Monaco, Consolas, Lucida Console, monospace",
    },
    fontSizes: {
      1: "56px",
      2: "48px",
      3: "32px",
      4: "28px",
      5: "24px",
      6: "20px",
      7: "18px",
      8: "16px",
      9: "14px",
      10: "13px",
      11: "12px",
    },
    space: {
      4: "4px",
      6: "6px",
      8: "8px",
      12: "12px",
      16: "16px",
      20: "20px",
      24: "24px",
      28: "28px",
      32: "32px",
      36: "36px",
      40: "40px",
      44: "44px",
      48: "48px",
      56: "56px",
      64: "64px",
      80: "80px",
      96: "96px",
      128: "128px",
      160: "160px",
      224: "224px",
      256: "256px",
    },
    sizes: {
      4: "4px",
      8: "8px",
      12: "12px",
      16: "16px",
      20: "20px",
      24: "24px",
      28: "28px",
      32: "32px",
      36: "36px",
      40: "40px",
      44: "44px",
      48: "48px",
      56: "56px",
      64: "64px",
      80: "80px",
      96: "96px",
      128: "128px",
      160: "160px",
      224: "224px",
      256: "256px",
    },
    radii: {
      1: "4px",
      2: "8px",
      3: "16px",
      4: "24px",
      round: "9999px",
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      bold: 600,
    },
    lineHeights: {
      1: "112%",
      2: "125%",
      3: "150%",
      4: "170%",
    },
    letterSpacings: {
      2: "2px",
    },
    borderWidths: {
      1: "1px",
      2: "2px",
    },
    borderStyles: {
      solid: "solid",
      uploadFocus: "2px dashed $colors$brand400",
      uploadSuccess: "2px dashed $colors$success400",
      uploadError: "2px dashed $colors$danger400",
    },
    shadows: {
      index1: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      index2:
        "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
      index3:
        "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
      index4:
        "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
      index5:
        "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
      index6: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
      index7: "0px 32px 64px -12px rgba(16, 24, 40, 0.14)",
      focus: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E4EEFF",
      focusError: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2",
    },
    zIndices: {
      loading: 10,
      header: 20,
      sidebar: 30,
      overlay: 40,
      drawer: 50,
      popover: 60,
      modal: 70,
      tooltip: 80,
      toast: 90,
      mainLoader: 100,
    },
    transitions: {
      backgroundTransition: "background .4s cubic-bezier(0, 0, .2, 1)",
      bgAndColor:
        "background .4s cubic-bezier(0, 0, .2, 1), color .4s cubic-bezier(0, 0, .2, 1)",
    },
  },
  media: {
    sm: "(max-width: 767px)",
    md: "(max-width: 1023px) and (min-width: 768px)",
    lg: "(min-width: 1024px)",
  },
  utils: {
    marginX: (value: Stitches.ScaleValue<"space">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: Stitches.ScaleValue<"space">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    visuallyHidden: () => ({
      position: "absolute !important",
      top: 0,
      width: "1px !important",
      height: "1px !important",
      padding: "0 !important",
      overflow: "hidden !important",
      border: "0 !important",
      clip: "rect(1px, 1px, 1px, 1px) !important",
    }),
    accelerate: (property: String) => ({
      transform: "translateZ(0)",
      backfaceVisibility: "hidden",
      perspective: "1000px",
      willChange: property,
    }),
    isDisabled: () => ({
      opacity: ".5",
      pointerEvents: "none",
    }),
    layerPositon: (args: PositionProps) => ({
      postion: args.position,
      top: args.top,
      right: args.right,
      bottom: args.bottom,
      left: args.left,
    }),
    flexLayout: (args: FlexProps) => ({
      display: args.display,
      alignItems: args.alignItems,
      justifyContent: args.justifyContent,
    }),
    notSelectable: () => ({
      userSelect: "none",
      textSizeAdjust: "none",
      "-webkit-touch-callout": "none",
      "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    }),
    baseInput: () => ({
      display: "flex",
      appearance: "none",
      height: "$40",
      color: "$neutral800",
      border: "1px solid $neutral400",
      padding: "0 $12",
      fontSize: "$9",
      backgroundColor: "$white",
      "&:focus-within": {
        borderColor: "$brand400",
        boxShadow: "$focus",
      },
    }),
    inputReset: () => ({
      appearance: "none",
      border: "none",
      fontSize: "$9",
      "&:focus": {
        outline: "none",
      },
    }),
    baseInputError: () => ({
      borderColor: "$danger500 !important",
      boxShadow: "$focusError !important",
    }),
  },
});

export type CSS = Stitches.CSS<typeof config>;
