import { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import storage from "../../../firebase";
import { ExperienceContext } from "../../../contexts/experience.context";
import { getExperience } from "../../../services";
import { badWordsStoreName, capitalizeFirstLetter } from "../../../utils/utils";
import CTA from "../CTA/CTA";
import "./Cartouche.css";

const Cartouche = () => {
  const { store, country } = useContext(ExperienceContext);
  const { experienceId } = useParams();
  const [m3u8Id, setm3u8Id] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(store);
    getExperience(experienceId as string)
      .then((response) => {
        console.log(response.data.url.split("/").pop());
        setm3u8Id(response.data.url.split("/").pop());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [store]);

  const download = async (filename: string) => {
    const url = await getDownloadURL(ref(storage, `2/${filename}`));
    const response = await fetch(url);
    let blob = await response.blob();
    blob = blob.slice(0, blob.size, "video/mp4");

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const mp4 = m3u8Id.split(".")[0];
    const videoUrl = `https://experience.snippetgroup.com/api/experience/2/${mp4}/tesoro_azul/download`;
    setLoading(true);
    fetch(videoUrl)
      .then(() => {
        download(`${mp4}.mp4`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  return (
    <div className="overlay-end fadeIn">
      {loading === true && (
        <div className="container-loading">
          <ReactLoading
            className="loading"
            type="spokes"
            color="#7AD6D1"
            width="8rem"
          />
        </div>
      )}
      <div className="overlay-end-container">
        <div className="cartouche-container">
          <p
            style={{
              fontSize: "4.5em",
              fontFamily: "DM Sans Bold",
              fontWeight: "bold",
              color: "#FFFFFF",
              lineHeight: "0.9",
              marginBottom: 0,
            }}
          >
            {`${capitalizeFirstLetter(store.name)},`}
          </p>
          <p
            style={{
              fontSize: "3em",
              fontFamily: "DM Sans Medium",
              lineHeight: "1.2em",
              color: "#FFFFFF",
            }}
          >
            la comunicación personalizada
            <br />
            ahora está en tus manos
          </p>
          <div>
            <CTA />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cartouche;
