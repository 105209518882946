import React, { FC, ReactElement } from "react";
import { Outlet } from "react-router-dom";

interface Props {
  children: ReactElement;
}

const Layout: FC<Props> = ({ children }) => (
  <main>{children || <Outlet />}</main>
);

export default Layout;
