import React, { FC } from "react";
import { IRoute } from "../entities";

interface Props {
  route: IRoute;
}

const LayoutContainer: FC<Props> = ({ route }) => {
  return <route.component {...route.componentProps} />;
};

export default LayoutContainer;
