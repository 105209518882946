import axios from "axios";

const SnippetApi = axios.create({
  baseURL: "https://experience.snippetgroup.com/api",
  headers: {
    "Content-type": "application/json",
  },
});

console.log(SnippetApi);

export default SnippetApi;
