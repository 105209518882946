import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyD8NElHLG6A4T_o0RmFhcUk6ASoats2S8c",
  authDomain: "snippet-bbf90.firebaseapp.com",
  projectId: "snippet-bbf90",
  storageBucket: "snippetgroup",
  messagingSenderId: "528620482768",
  appId: "1:528620482768:web:41ef3a1b23d9f0185aeed5",
});
// Firebase storage reference
const storage = getStorage(app);

export default storage;
