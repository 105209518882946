import React, { FC } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import LayoutContainer from "./LayoutContainer";
import routes from "./routes";

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} element={<route.layout />}>
            <Route
              path={route.path}
              key={route.path}
              element={<LayoutContainer route={route} />}
            />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
