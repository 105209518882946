const CTA = () => {
  return (
    <div>
      <button
        type="button"
        style={{
          // display: 'table-cell',
          verticalAlign: "middle",
          backgroundColor: "#ffffff",
          padding: "0.1em 0.8em",
          color: "#0355ff",
          fontFamily: "DM Sans Bold",
          fontSize: "3em",
          border: "0px",
          borderRadius: "60px",
          pointerEvents: "auto",
        }}
        onClick={() => {
          // @ts-ignore
          window.open("https://app.myme.net/access/signup", "_blank");
        }}
      >
        ¡Prueba ahora!
      </button>
    </div>
  );
};

export default CTA;
