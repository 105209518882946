import React, { useContext, useEffect, useRef, useState } from "react";
import "./Video.css";
import videojs from "video.js";
import { useParams } from "react-router-dom";
import VideoJS from "./VideoJS";
import { getExperience } from "../../services";
import { ExperienceContext } from "../../contexts/experience.context";
import { iOS } from "../../utils/utils";
import resize from "../../hooks/resize";
import { getDownloadURL, ref } from "firebase/storage";
import storage from "../../firebase";
import { truncate } from "fs";

const Video = () => {
  const { experienceId } = useParams();
  const videoRef = useRef(null);
  const [src, setSrc] = useState("");
  const { setStore } = useContext(ExperienceContext);

  useEffect(() => {

    getExperience(experienceId as string)
      .then((response) => {
        setStore(response.data.store);
        setSrc(response.data.url);
        console.log(response.data.url)
        console.log(JSON.stringify(response.data.url))
      })
      .catch((error) => {
        console.log(error);
      });
  }, [experienceId]);


  const playerRef = React.useRef(null);

  let videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    preload: "auto",
    // nativeControlsForTouch: true,
    controlBar: {
      children:
        [],
    },
    html5: {
      vhs: {
        overrideNative: true
      },
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
    },
    sources: [
      {
        src,
        type: "application/x-mpegURL",
      },
    ],
  };

  useEffect(() => {
    videoJsOptions =
    {
      ...videoJsOptions,
      sources: [
        {
          src,
          type: "application/x-mpegURL",
        },
      ],
    }
  }, [src]);

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore


  // @ts-ignore
  const handlePlayerReady = (player) => {

    resize(videoRef);

    playerRef.current = player;

    // videojs.Vhs.xhr.beforeRequest = function (options) {
    //   options.headers = {
    //     "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, Cache-Control",
    //   };
    //   return options;
    // };

    // You can handle player events here, for example:
    // player.playsInline(true);
    // player.playsinline(true);

    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <div ref={videoRef} className="video-wrapper" >
      <div className="video-rescaled-container">
        {src && (
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        )}
      </div>
    </div>
  );
};

export default Video;
