import {useState } from "react";

type WindowSize = {
  width: number;
  height: number;
};

// @ts-ignore
const resize = (ref) => {
  const aspectRatio = 16 / 9;

  function handleResize() {
    if (ref.current.clientWidth / ref.current.clientHeight > aspectRatio) {
      ref.current.children[0].setAttribute(
        "style",
        `height: ${ref.current.clientHeight.toString()}px; width: ${(
          ref.current.clientHeight * aspectRatio
        ).toString()}px; font-size: ${(
          (ref.current.clientHeight * aspectRatio * 16) /
          1920
        ).toString()}px`,
      );

      // this.renderer.setStyle(this.el.nativeElement.children[0].children[0], 'font-size', (h * aspectRatio * 16 / 1920).toString() + 'px');
    } else {
      ref.current.children[0].setAttribute(
        "style",
        `height: ${(
          ref.current.clientWidth / aspectRatio
        ).toString()}px; width: ${ref.current.clientWidth.toString()}px; font-size: ${(
          (16 * ref.current.clientWidth) /
          1920
        ).toString()}px`,
      );
      // this.renderer.setStyle(this.el.nativeElement.children[0].children[0], 'font-size', (16 * w / 1920).toString() + 'px');
    }
  }

  window.addEventListener("resize", handleResize);
  handleResize();
  return () => window.removeEventListener("resize", handleResize);
};

export default resize;
