import axios from "axios";
import SnippetApi from "./snippet.api";

export const getExperience = (experienceId: string) =>
  SnippetApi.get(`/experience/${experienceId}`);

export const createExperience = (data: any) =>
  SnippetApi.post("/experience/from_data", data);

export const getM3U8 = (m3u8Link: string) => axios.get(m3u8Link);
