// @ts-nocheck
// eslint-disable-file no-use-before-define
import React, { useContext, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { ExperienceContext } from "../../contexts/experience.context";
import { iOS } from "../../utils/utils";
import Cartouche from "../effects/Cartouche/Cartouche";
import { styled } from "../../styles/stitches.config";
import "./Video.css";

const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [cartouche, setCartouche] = React.useState(false);
  const { options, onReady } = props;
  const { experienceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { country, setStore } = useContext(ExperienceContext);
  const [audio, setAudio] = React.useState(new Audio("https://firebasestorage.googleapis.com/v0/b/snippetgroup/o/myme%2Fb2b_myme%2Fmedia%2Fb2b_myme_music.mp3?alt=media&token=c500eb00-8938-4440-82b8-ee11b2430272"));
  const [play, setPlay] = React.useState(false);
  const [quarter, setQuarter] = React.useState(false);
  const [half, setHalf] = React.useState(false);
  const [threeQuarter, setThreeQuarter] = React.useState(false);
  const [end, setEnd] = React.useState(false);
  const [shared, setShared] = React.useState(
    searchParams.get("shared") === "true" ? "compartido" : "",
  );
  const [playing, setPlaying] = React.useState(false);

  const handlePlayAudio = () => {
    audio.play();
  };

  const handlePauseAudio = () => {
    audio.pause();
  };

  const handlePlayVideo = () => {
    videoRef.current.play();
    audio.play();
  };

  const handlePauseVideo = () => {
    videoRef.current.pause();
    audio.pause();
  };

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoElement.setAttribute("webkit-playsinline", "true");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);

        // BigPlayButton.prototype.onClick = function () {
        //   handlePlayAudio()
        //   player.play();
        // }

        player.playsinline(true);

        player.on("ontouchstart", () => {
          if (player.paused()) {
            setPlaying(true);
            handlePlayVideo();
          } else {
            setPlaying(false);
            handlePauseVideo();
          }
        });

        player.on("play", () => {
          console.log("prueba play  ");
          if (player.currentTime() != player.duration()) {
            handlePlayAudio();
            setPlay(true);
            setPlaying(true);
          }
        });

        player.on("ended", () => {
          audio.pause();
          setPlaying(true);
        });

        player.on("pause", () => {
          console.log("prueba pause");
          audio.pause();
          if (player.currentTime() != player.duration()) {
            setPlaying(false);
          }
        });

        player.on("seeked", () => {
          console.log("prueba seeking");
          console.log(player.paused());
          if (player.paused() && !iOS()) {
            audio.currentTime = player.currentTime();
            audio.pause();
          } else {
            console.log("prueba seeking 1");
            if (!iOS()) {
              console.log("prueba seeking 2");
              audio.currentTime = player.currentTime();
            }
          }
        });

        player.on("timeupdate", () => {
          if (
            player.currentTime() > player.duration() - 9.2 &&
            player.duration() > 0
          ) {
            setCartouche(true);
          } else {
            setCartouche(false);
          }
          if (
            player.currentTime() > 1 &&
            iOS() &&
            player.currentTime() !== player.duration()
          ) {
            handlePlayAudio();
          }
          if (player.currentTime() > player.duration() / 4) {
            if (!quarter) {
              setQuarter(true);
            }
          }
          if (player.currentTime() > player.duration() / 2) {
            if (!half) {
              setHalf(true);
            }
          }
          if (player.currentTime() > 3 * (player.duration() / 4)) {
            if (!threeQuarter) {
              setThreeQuarter(true);
            }
          }
          if (player.currentTime() >= player.duration() - 0.5) {
            if (!end) {
              setEnd(true);
            }
          }
          else {
            setEnd(false);
          }
        });
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
      console.log(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    console.log(audio);
  }, [audio]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => { }, [playerRef]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        audio.pause();
        playerRef.current.pause();
      }
    };

    const handleBeforeUnload = () => {
      audio.pause();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      audio.pause();
      setAudio(null);
    };
  }, [audio]);

  const StyledPlayButton = styled("button", {
    zIndex: "9999999",
    position: "absolute",
    margin: "0 auto",
    cursor: "pointer",
    fontSize: "3em",
    border: "none",
    backgroundColor: "transparent",
  });

  const StyledPlaySVG = styled("img", {
    cursor: "pointer",
    height: "6em",
  });

  return (
    <div data-vjs-player className="video-inner-wrapper">
      <div className="video-container" ref={videoRef} />
      {cartouche && <Cartouche />}
      {!playing && !end && (
        <StyledPlayButton
          onClick={() => {
            handlePlayAudio();
            playerRef.current.play();
            setPlaying(true);
          }}
        >
          <StyledPlaySVG
            src={`${process.env.PUBLIC_URL}/images/playIcon.svg`}
          />
        </StyledPlayButton>
      )}
    </div>
  );
};

export default React.memo(VideoJS);
