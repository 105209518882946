import axios from "axios";
import {
  FC,
  ReactElement,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface AppContextType {
  store: any;
  setStore: (store: any) => void;
  country: any;
  setCountry: (country: any) => void;
  originalCountry: any;
  setOriginalCountry: (originalCountry: any) => void;
}

interface Props {
  children: ReactElement;
}

const ExperienceContext = createContext<AppContextType>({
  store: {},
  setStore: () => {},
  country: {},
  setCountry: () => {},
  originalCountry: {},
  setOriginalCountry: () => {},
});

const ExperienceContextProvider: FC<Props> = ({ children }) => {
  const [store, setStore] = useState(null);
  const [country, setCountry] = useState("");
  const [originalCountry, setOriginalCountry] = useState("");

  const contextValue = useMemo(
    () => ({
      store,
      setStore,
      country,
      setCountry,
      originalCountry,
      setOriginalCountry,
    }),
    [store, setStore, country, setCountry, originalCountry],
  );

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const { data } = response;
        if (data.country_name !== "Mexico") {
          setCountry("EEUU");
        } else {
          setCountry("MX");
        }
        setOriginalCountry(data?.country_name);
      })
      .catch(() => {
        setCountry("EEUU");
      });
  }, []);

  return (
    <ExperienceContext.Provider value={contextValue}>
      {children}
    </ExperienceContext.Provider>
  );
};
export { ExperienceContext, ExperienceContextProvider };
