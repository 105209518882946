import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import badWords from "../bad_words.json";
import storage from "../firebase";
import names from "../names_tesoro_azul.json";
import songs from "../songs_tesoro_azul.json";

function CleanName(name: string): string {
  const nameWithoutAccents = name
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  // @ts-ignore
  // console.log(nameWithoutAccents.split(" ").shift().toLowerCase());
  // console.log(nameWithoutAccents.split(" ").pop().toLowerCase());
  return [
    // @ts-ignore
    nameWithoutAccents.split(" ").shift().toLowerCase(),
    // @ts-ignore
    nameWithoutAccents.split(" ").pop().toLowerCase(),
  ];
}

export function GetSong(age: number): string {
  let song = "";
  const birthYear = new Date().getFullYear() - age;
  // @ts-ignore
  if (birthYear >= 2003 || birthYear <= 1975) {
    song = "generico";
  } else {
    // @ts-ignore
    song = Object.keys(songs).find((key) => songs[key] === String(birthYear));
  }
  return song;
}

export function capitalizeFirstLetter(name: string) {
  const separatedName = name.split(" ");
  if (separatedName.length > 1) {
    separatedName.forEach((word, index) => {
      separatedName[index] =
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return separatedName.join(" ");
  }
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

export function FormatName(name: string): string {
  // @ts-ignore
  let firstTwoWords = "";
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/\s+/g, " ").trim();
  if (name.split(" ").length > 2) {
    firstTwoWords = name.split(" ").shift() as string;
  } else {
    firstTwoWords = name.split(" ").slice(0, 2).join(" ");
  }
  let nameWithoutAccents = firstTwoWords
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  nameWithoutAccents = capitalizeFirstLetter(nameWithoutAccents);
  let badword = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < badWords.words.length; i++) {
    if (
      nameWithoutAccents.toLowerCase().includes(badWords.words[i].toLowerCase())
    ) {
      badword = true;
    }
  }

  // eslint-disable-next-line array-callback-return
  nameWithoutAccents.split(" ").map((word) => {
    if (badWords.words.includes(word.toLowerCase())) {
      badword = true;
    }
  });
  return badword
    ? "Marco,\\N hoy tienes\\N una cita "
    : `Marco,\\N hoy viene\\N${nameWithoutAccents.replace(
        /[^a-zA-Z0-9]/g,
        "\\N",
      )}`;
}

export function badWordsStoreName(name: string) {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/\s+/g, " ").trim();
  let badword = false;
  let aux = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // eslint-disable-next-line array-callback-return
  aux.split(" ").map((word: string) => {
    if (badWords.words.includes(word.toLowerCase())) {
      badword = true;
    }
  });
  aux = capitalizeFirstLetter(aux);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < badWords.words.length; i++) {
    if (aux.toLowerCase().includes(badWords.words[i].toLowerCase())) {
      badword = true;
    }
  }
  return badword ? "¡Sigue brindando!" : name;
}

export function GetCode(name: string): string {
  let code: string = "generico";

  const normalizedName = CleanName(name);

  let aux = Object.keys(names).find((key) =>
    // @ts-ignore
    names[key].includes(normalizedName[0]),
  );

  if (aux === undefined) {
    aux = Object.keys(names).find((key) => key === normalizedName[0]);
  }

  if (aux === undefined) {
    aux = Object.keys(names).find((key) =>
      // @ts-ignore
      names[key].includes(normalizedName[1]),
    );
  }

  if (aux === undefined) {
    aux = Object.keys(names).find((key) => key === normalizedName[1]);
  }

  code = aux !== undefined ? aux : code;
  console.log(code);
  return code;
}

export function HashImageName(name: string): string {
  const fireName = name.split(".")[0];
  const extension = name.split(".")[1];
  return `${fireName}-${Date.now()}.${extension}`;
}

export async function uploadImgFirebase(
  name: string,
  file: any,
  base64: boolean,
): Promise<string> {
  try {
    const fileName = `${HashImageName(name)}`;
    const storageRef = ref(storage, `2/images/${fileName}`);
    if (base64) {
      // eslint-disable-next-line no-param-reassign
      // file = file.replace("data:image/png;base64,", "");
      return await uploadString(storageRef, file, "data_url", {
        contentType: "image/jpg",
      }).then(() => {
        return fileName;
      });
    }

    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log(downloadURL);
    return fileName;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to upload image to Firebase storage.");
  }
}

export const iOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export default CleanName;
