import { IRoute } from "../entities";
import Layout from "../features/Layout";
import Landing from "../features/landing/Landing";
import UploadImg from "../features/viewUploadPhoto/ViewUploadPhoto";
import TakeImg from "../features/viewTakePhoto/ViewTakePhoto";
import EditImg from "../features/viewEditPhoto/editPhoto";
import Video from "../features/video/Video";

const routes: IRoute[] = [
  // {
  //   title: "Landing",
  //   name: "Landing",
  //   path: "/",
  //   componentProps: {},
  //   component: Landing,
  //   layout: Layout,
  // },
  // {
  //   title: "UploadImg",
  //   name: "UploadImg",
  //   path: "/uploadImg",
  //   componentProps: {},
  //   component: UploadImg,
  //   layout: Layout,
  // },
  // {
  //   title: "TakeImg",
  //   name: "TakeImg",
  //   path: "/takeImg",
  //   componentProps: {},
  //   component: TakeImg,
  //   layout: Layout,
  // },
  // {
  //   title: "EditImg",
  //   name: "EditImg",
  //   path: "/editImg",
  //   componentProps: {},
  //   component: EditImg,
  //   layout: Layout,
  // },
  {
    title: "Video",
    name: "Video",
    path: "/:experienceId",
    componentProps: {},
    component: Video,
    layout: Layout,
  },
];

export default routes;
